<template>
  <div>
    <div>
      <div class="content-header container-fluid bg-white">
        <div class="row">
          <div class="col-sm-4">
            <h4><i class="ti-world"> </i> 通用管理 | 教师列表</h4>
          </div>
        </div>
      </div>
      <div class="content">
        <div class="row" v-if="USER.ROLE == 'ADMIN'">
          <div class="col-md-12">
            <el-button type="warning" @click="setRole()">黏贴权限</el-button>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div class="w-block" style="padding: 2px">
              <el-input
                v-model="grid.sea.KEY"
                placeholder="请输入关键字"
                style="width: 300px"
              >
                <el-button
                  slot="append"
                  icon="el-icon-search"
                  @click="filter"
                ></el-button>
              </el-input>
            </div>
            <wgrid
              :data="grid.ls"
              v-loading="grid.loading"
              :total="grid.total"
              :page-index="grid.sea.PAGE_INDEX + 1"
              @page-changed="loadPage"
              @selection-change="selectionChange"
            >
              <el-table-column type="selection" width="55"></el-table-column>
              <el-table-column label="姓名" width="150" align="center">
                <template slot-scope="scope">
                  {{ scope.row.NAME
                  }}<span v-if="scope.row.ROLE == 'ADMIN'" style="color: red"
                    >【管理员】</span
                  >
                </template>
              </el-table-column>
              <el-table-column
                prop="MOBILE"
                label="手机"
                width="120"
                align="center"
              />
              <el-table-column label="微信" width="50" align="center">
                <template slot-scope="scope">
                  <i class="el-icon-check" v-if="scope.row.OPEN_ID" />
                </template>
              </el-table-column>
              <el-table-column label="操作" width="300">
                <template slot-scope="scope">
                  <el-button
                    type="text"
                    icon="el-icon-edit"
                    @click="rowEdit(scope.row)"
                    v-if="USER.ROLE == 'ADMIN'"
                    >分配权限</el-button
                  >
                  <el-button
                    type="text"
                    icon="el-icon-coordinate"
                    @click="roleEdit(scope.row)"
                    v-if="USER.ROLE == 'ADMIN'"
                    >修改角色</el-button
                  >
                  <el-button
                    type="text"
                    icon="el-icon-coordinate"
                    @click="copyRole(scope.row)"
                    v-if="USER.ROLE == 'ADMIN'"
                    >复制授权</el-button
                  >
                </template>
              </el-table-column>
              <el-table-column prop="ROLE_NAMES" label="权限"></el-table-column>
            </wgrid>
          </div>
        </div>
      </div>
    </div>
    <div>
      <Edit ref="role_edit" />
      <RoleEdit ref="role_roleedit" @on-saved="filter" />
    </div>
  </div>
</template>

<script>
import Wgrid from "@/components/wgrid";
import Edit from "./edit";
import RoleEdit from "./roleedit";
export default {
  name: "index",
  components: { Wgrid, Edit, RoleEdit },
  data() {
    return {
      grid: {
        sea: {
          PAGE_SIZE: 12,
          PAGE_INDEX: 0,
          ROLE: "",
          KEY: "",
        },
        ls: [],
        total: 0,
        loading: false,
      },
      roles: [],
      selectTeachers: [],
      USER: JSON.parse(localStorage.getItem("userInfo")),
      copyMenuIds: [],
    };
  },
  created() {
    this.getList();
    this.getRoles();
  },
  methods: {
    filter() {
      this.grid.sea.PAGE_INDEX = 0;
      this.grid.ls = [];
      this.getList();
    },
    loadPage(pi) {
      this.grid.sea.PAGE_INDEX = pi - 1;
      this.getList();
    },
    getList() {
      let self = this;
      this.grid.loading = true;
      this.whale.remote.getCollection({
        url: "/api/School/ORG/TeacherApi/GetList",
        data: this.grid.sea,
        completed: function (its, n) {
          self.grid.ls = its;
          self.grid.total = n;
          self.grid.loading = false;
        },
      });
    },
    getRoles() {
      let self = this;
      this.whale.remote.getCollection({
        url: "/api/School/ACL/RoleApi/GetList",
        completed: function (its) {
          self.roles = its;
        },
      });
    },
    rowEdit(r) {
      this.$refs.role_edit.init(r);
    },
    roleEdit(r) {
      this.$refs.role_roleedit.init(r);
    },
    copyRole(val) {
      console.log(val);
      this.copyMenuIds = val.MENUIDS;
      this.$message.success("复制成功");
    },
    selectionChange(val) {
      console.log(val);
      this.selectTeachers = val;
    },
    setRole(val) {
      console.log(val);
      if (this.selectTeachers.length == 0) return false;
      var teacherIds = this.selectTeachers.map((c) => c.ID);

      console.log("teacherIds", teacherIds);
      let self = this;
      this.whale.remote.getResult({
        url: "/api/School/ORG/TeacherApi/CopyRoles",
        data: { ids: teacherIds, mids: self.copyMenuIds },
        completed: function (result) {
          console.log("result", result);
          self.$message.success("授权成功");
          self.getList();
        },
      });
    },
  },
};
</script>

